@use "sass:math";
@import "utilities";

@mixin container {
  max-width: 100%;
  // padding-left: 20px;
  // padding-right: 20px;
  @include center-block;
  @media ($mediaFromTabletV) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media ($mediaMobile) {
    padding-left: 10px;
    padding-right: 10px;
    }
}
@mixin container-lg {
  @media ($mediaDesktop) {
    @include container;
    width: 1416px;
  }
}
@mixin container-md {
  @media ($mediaTabletH) {
    @include container;
    width: 984px;
  }
}
@mixin container-sm {
  @media ($mediaTabletV) {
    @include container;
    width: 728px;
  }
}
@mixin container-xs {
  @media ($mediaMobile) {
    @include container;
    max-width: 720px;
  }
}

@mixin row {
  //margin-left: -8px;
  //margin-right: -8px;
  @include flex;
  @include flex-wrap;

  > * {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    @include flex-grow(0);
    @include flex-shrink(0);
    @include flex-basis(auto);
  }
}

@function getWidth($cols) {
  $result: math.div($cols * 100%, 12);
  @return $result;
}
@mixin col($cols) {
  width: getWidth($cols);
}
@mixin inset($cols) {
  margin-left: -1 * getWidth($cols);
}
@mixin offset($cols) {
  margin-left: getWidth($cols);
}
  /*                [1. Core, dependencias]                          */
  @import "../../../node_modules/@busacca/ng-pick-datetime/assets/style/picker.min.css";
  /*                [2. Fuentes]                          */
  @import "../fonts/plex-sans-condensed.css";
  @import '~material-icons/iconfont/material-icons.css'; //sólo para iconos de neoMaterial
  @import '../fonts/icons-matrix.css'; //iconos Matrix
  @import '../fonts/icomoon.css'; //iconos previos a migración iconos matrix

    /*                [3. Calendario]                          */
  @import "../../../node_modules/angular-calendar/css/angular-calendar.css";
.main {
  /*                [4. Utilities]                          */
  // @import "utilities";
  @import "variables/variables";

  @import 'mixins/flex';
  /*@import 'mixins/forms';*/
  @import 'mixins/links';
  @import 'mixins/layout';
  @import 'mixins/other';
  @import 'mixins/tipography';
  @import 'mixins/campos';

  @import "reset";
  @import "layout";
  @import "tipography";
  //TODO: Revisar diferencias entre links y links_matrix
  @import "links_matrix";
  //@import "links";
  /*@import "forms";
  @import "modals";*/
  @import "tags";
  @import "aux-clases";
  /*@import "tables";*/
  @import "flex";

  /*                [5. Angular Material]                          */
  @import "angular-material";

  

  /* TODO: REPLANTEAR, estas reglas estaban en tipography.scss aplicándose al body pero tras añadir la clase main al body para solventar
   * la problemática de la sobreescritura de los scss de neoMaterial custom sobre los scss de neoMaterial de node_modules evitando el uso de !important,
   * es una de las opciones para tener que añadir una única vez la clase .main como padre del resto de importaciones en vez
   * de hacerlo en cada uno de los scss que se importan en este archivo
   */
  margin: 0;
  background-color: $grey5;
  color: $black;
  font-family: $primaryFont;
  @include text-p5;
  font-weight: 400;

  //TODO: REVISAR PARA QUE NO HAYA QUE SOBREESCRIBIR (header.component.html span hermano de ngx-avatar)
  button {
    font-family:$primaryFont!important;
  }
}


